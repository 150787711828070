<template>
  <div>
    <subscribe v-if="showPlansPopup" :subscribePayload="subscribepayload" :closePopup="() => toggleSubscribePopup(false)" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";

export default {

  data() {
    return {
      subscribepayload: null,
      showPlansPopup: false,
      couponCode : null,
      planid : null
    };
  },
	  mixins: [Utility],

  computed: {
      ...mapGetters(["subscriberid", "getPlanDeeplinkPath"]),
  },

  mounted() {

    this.showPlansPopup = true;

    this.planid = this.$route.params.planId;
    this.couponCode = this.$route.params.coupon;

    console.log("THIS IS FROM SEE ALL PLANS --- > ", this.planid, this.couponCode)

    let payload = {
      listPlans: "ALL",
      listType: "onlySubscribe"
    };

    this.subscribepayload = payload;
  },

  methods: {

    ...mapMutations(["setPlanDeeplinkPath"]),

    toggleSubscribePopup(state) {
			if (this.getPlanDeeplinkPath) {
				this.$router.push({ name: "Home"});
					}else {
				this.$router.push({ name: "Home"});
    }
	}
  },

  components: {
    subscribe: () => import(/* webpackChunkName: "subscribe" */ "@/components/Popups/Subscribe.vue"),
  },
};
</script>

<style></style>
